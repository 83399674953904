<template>
  <LayoutThumbnailComponent
    linkName="news-slug"
    :localizedData="localizedData"
    :linkType="linkType"
    :class="$style.root"
    :image="coverImage"
    :clusterBackgroundColor="clusterBackgroundColor"
  >
    <template #tags>
      <SharedLinkToClusterComponent
        v-if="clusters?.length && showClusters"
        v-for="cluster in clusters"
        :key="cluster?.id"
        :data="cluster?.attributes"
        linkType="dynamic"
        sizeOption="tiny"
      />
    </template>

    <div :class="$style.information">
      <BaseDateTimeComponent
        v-if="attributes?.date_posted"
        :date="toDate(attributes?.date_posted)"
        format="DD.MM.YY"
        :class="$style.datePosted"
      />
      <div>
        {{ title }}
      </div>
    </div>
  </LayoutThumbnailComponent>
</template>

<script setup>
const props = defineProps({
  data: Object,
  linkType: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'dynamic'].includes(value)
  },
  showClusters: {
    type: Boolean,
    default: true,
  }
})

const attributes = computed(() => {
  return baseStrapiGetAttributes(props?.data)
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(attributes?.value)
})

const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

const coverImage = computed(() => localizedData.value?.cover_image?.data?.attributes)

const clusters = computed(() => {
  return localizedData.value?.cluster_entries?.data
})

const clusterBackgroundColor = computed(() => {
  if ( clusters?.value?.length === 1 ) {
    return clusters?.value[0]?.attributes?.background_color
  } else {
    return null
  }
})

const toDate = (date) => {
  return dayJs(date).toDate()
}
</script>

<style module>
.root {
  --layout--thumbnail--aspect-ratio: 1;
}

.information {
  composes: font-size-small from global;

  margin-top: var(--unit--spacer);

  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: baseline;
  grid-gap: var(--unit--spacer);
}

.datePosted {
  composes: font-size-x-small from global;
  color: var(--color--blue);
}
</style>
